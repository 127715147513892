import * as React from 'react';
import { Helmet } from 'react-helmet';
import Feudle from '../../components/Feudle/index';
import Logo from '../../components/Logo';

const FeudlePage = () => {
	return (
		<>
			<Helmet>
				<title>Google Feudle</title>
			</Helmet>
			<Logo isFeudle />
			<Feudle />
		</>
	);
};
export default FeudlePage;
